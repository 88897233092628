// ***************
// Native OS Communication
// TCF v2.0 compliant consent collector
// Handles Native to Web communication
//
// _ctx: global Compliance class context
//
// @author: Primož Bevk
// ***************

import { status, platform } from './flags'
import { resValidate, initModule } from './core'
import { CLS, setCLSValue, deleteCLSValueByKey } from './ls'

export const onCMD = (json, _ctx) => {
  _ctx.debug.log('DEBUG', 'onComplianceModuleData() received:')
  if (typeof json === 'string') json = JSON.parse(json) // parse string to json on android
  _ctx.moduleType = json.t
  _ctx.cData = json.p
  console.log(json)
  _ctx.setProp('regulation', _ctx.cData.aR ? _ctx.cData.aR : 'GDPR')

  // _ctx.debug.log('DEBUG', JSON.stringify(_ctx.cData))
  _ctx.initCollector(_ctx.moduleType)
  initModule(_ctx.moduleType, _ctx)
}

export const setEvent = (e, _ctx) => {
  _ctx.debug.log('INFO', 'setEvent')
  _ctx.cData.ce = _ctx.cData.ce || []
  _ctx.cData.ce.push(e)
}

export const sendResult = (_ctx) => {
  var res = {
    rT: '',
  }
  if (!_ctx.TCF20.onResultSent) {
    _ctx.TCF20.onResultSent = true
    if (_ctx.cData) {
      // validate
      _ctx.cData = resValidate(_ctx.cData || {}, 'consent_tcf20')
      // fill data
      _ctx.cData.sP.consent_tcf20.v = _ctx.version
      _ctx.cData.sP.consent_tcf20.t = new Date().getTime()
      _ctx.cData.sP.consent_tcf20.r.consentString = _ctx.TCF20.getEncodedComplianceString()
      if (_ctx.nonIAB.isACStringSet) {
        _ctx.cData.sP.consent_tcf20.r.IABTCF_AddtlConsent = _ctx.nonIAB.acString()
        setCLSValue('IABTCF_AddtlConsent', _ctx.nonIAB.acString(), _ctx)
      } else {
        _ctx.cData.sP.consent_tcf20.r.IABTCF_AddtlConsent = null
        setCLSValue('IABTCF_AddtlConsent', null, _ctx)
      }
      _ctx.cData.sP.consent_tcf20.r.selected = _ctx.TCF20.ccs
      _ctx.cData.sP.consent_tcf20.r.gts = _ctx.gts
      CLS(_ctx.TCF20.nativeTCModel(), _ctx)
      _ctx.cData.nonIAB = _ctx.nonIAB.getConsents()
      _ctx.cData.sP.consent_tcf20.r.authorisedNonIabVendor = _ctx.nonIAB.authorisedVendors()
      _ctx.cData.sP.consent_tcf20.r.forbiddenNonIabVendor = _ctx.nonIAB.restrictedVendors()
      _ctx.cData.gvl = null // remove gvl to save on parsing performance on client
      // remove consent_gdpr data from local storage and result; user is not in consent_gdpr mode!
      _ctx.cData.sP.consent_gdpr = null // remove consent_tcf20 result (if it exists)

      res.rT = 'success'
      res.p = _ctx.cData
    } else {
      res.rT = 'break'
      res.failReason = 'CMD was not initialised!'
    }
    _ctx.debug.log('INFO', 'sendComplianceResult')
    _ctx.debug.log('DEBUG', JSON.stringify(res))
    call('onResult', JSON.stringify(res), _ctx)
  } else {
    res.rT = 'break'
    res.failReason = 'TCF2.0 result was already sent!'
    _ctx.debug.log('INFO', 'logComplianceResult')
    _ctx.debug.log('DEBUG', JSON.stringify(res))
  }
}

// send non IAB result (gdpr, lgpd)
export const sendNoniabResult = (_ctx) => {
  var res = {
    rT: '',
  }
  if (!_ctx.nonIAB.onResultSent) {
    _ctx.nonIAB.onResultSent = true
    if (_ctx.cData) {
      // validate
      _ctx.cData = resValidate(_ctx.cData || {}, _ctx.moduleType)
      // fill data
      _ctx.cData.sP[_ctx.moduleType].v = _ctx.version
      _ctx.cData.sP[_ctx.moduleType].t = new Date().getTime()
      _ctx.cData.sP[_ctx.moduleType].r.gts = _ctx.gts
      _ctx.cData.nonIAB = _ctx.nonIAB.getConsents()
      _ctx.cData.sP[
        _ctx.moduleType
      ].r.authorisedNonIabVendor = _ctx.nonIAB.authorisedVendors()
      _ctx.cData.sP[
        _ctx.moduleType
      ].r.forbiddenNonIabVendor = _ctx.nonIAB.restrictedVendors()
      _ctx.cData.gvl = null // remove gvl to save on parsing performance on client
      // remove IABTCF data from local storage and result; user is not in TCF20 mode!
      _ctx.cData.sP.consent_tcf20 = null // remove consent_tcf20 result (if it exists)
      deleteCLSValueByKey('IABTCF', _ctx)

      res.rT = 'success'
      res.p = _ctx.cData
    } else {
      res.rT = 'break'
      res.failReason = 'CMD was not initialised!'
    }
    _ctx.debug.log('INFO', 'sendComplianceResult')
    _ctx.debug.log('DEBUG', JSON.stringify(res))
    call('onResult', JSON.stringify(res), _ctx)
  } else {
    res.rT = 'break'
    res.failReason = 'NonIAB result was already sent!'
    _ctx.debug.log('INFO', 'logComplianceResult')
    _ctx.debug.log('DEBUG', JSON.stringify(res))
  }
}

// outbound communication with app
export const call = (type, msg, _ctx) => {
  try {
    if (_ctx.os === platform.ANDROID) {
      if (type === 'onResult') {
        window.complianceBridge.onResult(msg)
        _ctx.setProp('collectorResultSent', true)
      } else if (type === 'onShown') {
        if (_ctx.cmV && _ctx.cmV >= 2.41) {
          window.complianceBridge.onShown(JSON.stringify(msg))
        } else {
          window.complianceBridge.onShown(msg.screenId)
        }
      } else if (type === 'getComplianceModuleData') {
        _ctx.setProp('status', status.READY_TO_RECEIVE)
        window.complianceBridge.getComplianceModuleData()
      } else if (type === 'closeWebApp') {
        window.complianceBridge.closeWebApp(msg)
      } else if (type === 'onConsole') {
        // report console output on Android
      } else if (type === 'onEvent') {
        if (msg.send) {
          delete msg.send
          window.complianceBridge.onEvent(JSON.stringify(msg))
        }
        setEvent(msg, _ctx)
      } else throw 'Client postMessage: Invalid Type'
    } else if (_ctx.os === platform.IOS) {
      if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.compliance
      ) {
        if (type === 'onResult') {
          window.webkit.messageHandlers.compliance.postMessage({
            onResult: msg,
          })
          _ctx.setProp('collectorResultSent', true)
        } else if (type === 'onShown') {
          if (_ctx.cmV && _ctx.cmV >= 2.31) {
            window.webkit.messageHandlers.compliance.postMessage({
              onShown: JSON.stringify(msg),
            })
          } else {
            window.webkit.messageHandlers.compliance.postMessage({
              onResult: 'onShown',
            })
          }
        } else if (type === 'getComplianceModuleData') {
          _ctx.setProp('status', status.READY_TO_RECEIVE)
          window.webkit.messageHandlers.compliance.postMessage({
            onResult: 'getComplianceModuleData',
          })
        } else if (type === 'closeWebApp') {
          window.webkit.messageHandlers.compliance.postMessage({
            onResult: 'closeWebApp',
          })
        } else if (type === 'onConsole') {
          window.webkit.messageHandlers.compliance.postMessage({
            onConsole: msg,
          })
        } else if (type === 'onEvent') {
          if (msg.send) {
            delete msg.send
            window.webkit.messageHandlers.compliance.postMessage({
              onEvent: JSON.stringify(msg),
            })
          }
          setEvent(msg, _ctx)
        } else throw 'Client onResult: Invalid Type'
      } else console.log('iOS Client not initialised! ' + type + ' call failed')
    } else if (_ctx.os === platform.WEB) {
      if (window.parent) {
        if (type === 'onResult') {
          window.parent.postMessage({ onResult: msg }, '*')
          _ctx.setProp('collectorResultSent', true)
        } else if (type === 'onShown') {
          window.parent.postMessage({ onShown: true }, '*')
        } else if (type === 'getComplianceModuleData') {
          _ctx.setProp('status', status.READY_TO_RECEIVE)
          window.parent.postMessage({ getComplianceModuleData: true }, '*')
        } else if (type === 'closeWebApp') {
          window.parent.postMessage({ closeWebApp: true }, '*')
        } else if (type === 'onConsole') {
          window.parent.postMessage({ onConsole: msg }, '*')
        } else if (type === 'onEvent') {
          if (msg.send) {
            delete msg.send
            window.parent.postMessage({ onEvent: JSON.stringify(msg) }, '*')
          }
          setEvent(msg, _ctx)
        } else throw 'Client postMessage: Invalid Type'
      } else console.log('Web Client not initialised! ' + type + ' call failed')
    } else {
      throw 'Client onResult: OS not supported or undefined'
    }
    if (type !== 'onConsole') _ctx.debug.log('INFO', type + ' EVENT')
  } catch (e) {
    _ctx.debug.error(e)
  }
}

// register listener for WEB events
export const listen = (_ctx) => {
  window.addEventListener('message', (e) => {
    if (e.data && e.data !== 'undefined') {
      if ('onComplianceModuleData' in e.data) {
        _ctx.onComplianceModuleData(e.data.onComplianceModuleData)
      } else if ('setFlag' in e.data) {
        _ctx.setFlag(e.data.setFlag)
      } else if ('onConsole' in e.data) {
        // avoid infinite loop
      } else {
        _ctx.debug.error('Unrecognised event: ' + JSON.stringify(e.data))
      }
    }
  })
}

// open external url, with help of client
export const openExternalUrl = (url, _ctx) => {
  try {
    url = _ctx.getQPS(url)
    setTimeout(() => {
      _ctx.debug.log('DEBUG', 'openExternalUrl ' + url)
      if (_ctx.collectorResultSent) {
        throw 'Client openExternalUrl: Action blocked, collector already finished'
      } else if (_ctx.os === platform.ANDROID) {
        window.complianceBridge.openExternalUrl(url)
      } else if (
          _ctx.os === platform.IOS &&
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.compliance
      ) {
        window.webkit.messageHandlers.compliance.postMessage({
          openExternalUrl: url,
        })
      } else if (_ctx.os === platform.WEB) {
        window.open(url, '_blank')
      } else {
        throw 'Client openExternalUrl: OS not supported or undefined'
      }
    }, 100)
  } catch (e) {
    _ctx.debug.error(e)
  }
}
