// ***************
// Non IAB Vendors handler
//
// @author: Primož Bevk
// ***************

import { status, navigate } from '../utils/flags'
import { sendNoniabResult } from '../utils/native'

class NonIAB {
  constructor(_ctx) {
    this._ctx = _ctx
    this.consents = {}
    this.isStateModified = false
    this.onResultSent = false
    this.isACStringSet = false
    this.update(undefined, false, () => { })
  }

  update(nonIAB, vl, resolve) {
    if (vl) {
      this.vendorList = vl
      // if (this._ctx.moduleType === "consent_tcf20" && this.vendorList.filter(e => e.id === 'GoogleATP').length !== 0) this.vendorList.filter(e => e.id === 'GoogleATP')[0].privacyPolicyUrl = this._ctx.this.$compliance.privacyUrl().then((pp) => {this.policyUrl = pp})
      this.unsetVendorConsent('all', false) // set initial state
      this._ctx.debug.log('INFO', 'nonIAB vendor-list.json updated')
    } else {
      this.vendorList = [] // initialise as empty if not provided
    }
    if (nonIAB) {
      for (var c in nonIAB) {
        if (this.getVendorById(nonIAB[c].id))
          // only apply consents for vendors we have in our list
          nonIAB[c].consent
            ? this.setVendorConsent(nonIAB[c].id, false)
            : this.unsetVendorConsent(nonIAB[c].id, false)
      }
    }
    this._ctx.debug.log('DEBUG', 'nonIAB INIT')
    // set status to ready if initialised as part of consent_gdpr module
    if (
      (this._ctx.moduleType === 'consent_gdpr' ||
        this._ctx.moduleType === 'consent_lgpd') &&
      nonIAB !== undefined &&
      vl !== false
    )
      this._ctx.setProp('status', status.READY)
    resolve(navigate.NAVIGATE)
  }

  updateState(s) {
    // if TCF20 module is active, we have to dispatch updateState event to TCF20 module aswell (needs to be aware of state change from this module)
    if (this._ctx.TCF20) this._ctx.TCF20.updateState(s)
    // update state only if app is ready; otherwise drop updateState request
    // (triggered by internal process, not by user as app was not shown to user yet!)
    console.log('update state')
    if (this._ctx.status === status.READY) {
      this._ctx.debug.log('DEBUG', 'NonIAB state update')
      console.log('update state READY')
      this.isStateModified = s
      if (this._ctx.GlobalAcceptReject) {
        this._ctx.GlobalAcceptReject.update()
      } else {
        setTimeout(() => {
          this.updateState(s)
        }, 100)
      }
    }
  }

  getVendors() {
    this._ctx.debug.log('DEBUG', 'fetching non IAB vendors')
    return this.vendorList
  }

  getVendorById(id) {
    return this.vendorList.filter((vendor) => {
      return vendor.id === id
    })[0]
  }

  authorisedVendors() {
    return this.getConsents()
      .filter((vendor) => {
        return vendor.consent
      })
      .map((vendor) => {
        return vendor.id
      })
      .join(',')
  }

  restrictedVendors() {
    return this.getConsents()
      .filter((vendor) => {
        return !vendor.consent
      })
      .map((vendor) => {
        return vendor.id
      })
      .join(',')
  }

  getConsents() {
    return Object.values(this.consents)
  }

  getVendorConsent(id) {
    return this.consents[id].consent
  }

  // return true if user has consented to all providers
  hasConsented() {
    if (this.getConsents().length > 0)
      return (
        this.getConsents().filter((consent) => {
          return consent.consent === true
        }).length === this.getConsents().length
      )
    else return false
  }

  setVendorConsent(id, isUserAction = true) {
    if (id === 'all') {
      this.vendorList.forEach(
        (item) => (this.consents[item.id] = this.ci(item, true))
      )
    } else {
      if (Array.isArray(id) || typeof id === 'object') {
        id.forEach(
          (item) =>
            (this.consents[item] = this.ci(this.getVendorById(item), true))
        )
      } else {
        this.consents[id] = this.ci(this.getVendorById(id), true)
      }
    }
    this.toggleACString(true, id)
    this.updateState(isUserAction)
  }

  unsetVendorConsent(id, isUserAction = true) {
    if (id === 'all') {
      this.vendorList.forEach(
        (item) => (this.consents[item.id] = this.ci(item, false))
      )
    } else {
      if (Array.isArray(id) || typeof id === 'object') {
        id.forEach(
          (item) =>
            (this.consents[item] = this.ci(this.getVendorById(item), false))
        )
      } else {
        this.consents[id] = this.ci(this.getVendorById(id), false)
      }
    }
    this.toggleACString(false, id)
    this.updateState(isUserAction)
  }

  toggleACString(toggle, id) {
    if (this._ctx.moduleType === 'consent_tcf20' && toggle) {
      // toggle on
      if (id === 'all') {
        this.isACStringSet = true
        this._ctx.debug.log('DEBUG', 'AC String set')
      } else if (Array.isArray(id) || typeof id === 'object') {
        id.forEach((item) => {
          if (item === 'GoogleATP') {
            this.isACStringSet = true
            this._ctx.debug.log('DEBUG', 'AC String set')
          }
        })
      } else {
        if (id === 'GoogleATP') {
          this.isACStringSet = true
          this._ctx.debug.log('DEBUG', 'AC String set')
        }
      }
    } else if (this._ctx.moduleType === 'consent_tcf20') {
      // toggle off
      this.isACStringSet = false
      this._ctx.debug.log('DEBUG', 'AC String unset')
    }
  }

  acString() {
    return '1~' + this._ctx.cmp.acStringVendors
  }

  ci(v, consent) {
    return {
      id: v.id,
      name: v.name,
      consent: consent,
      timestamp: new Date().getTime(),
    }
  }

  acceptAll() {
    this.setVendorConsent('all')
    sendNoniabResult(this._ctx)
  }

  rejectAll() {
    this.unsetVendorConsent('all')
    sendNoniabResult(this._ctx)
  }

  save() {
    sendNoniabResult(this._ctx)
  }
}
export default NonIAB
