// ***************
// Age Gate
// O7 implementation of the Age Gate collector
//
// _ctx: global Compliance class context
//
// @author: Primož Bevk
// ***************
import * as Sentry from '@sentry/vue'
import { resValidate } from '../utils/core'
import { status, navigate } from '../utils/flags'
import { call } from '../utils/native'
import { keyExists, setCLSValue } from '../utils/ls'

class AgeGate {
  constructor(ageGate, _ctx) {
    this._ctx = _ctx
    this.resolvedBy = 'click'
    this.defaultAgeLimits = {
      18: ['BR'],
      16: [
        'HR',
        'DE',
        'HU',
        'IE',
        'LI',
        'LU',
        'NL',
        'PL',
        'RO',
        'SK',
        'SI',
        'CH',
      ],
      15: ['CZ', 'FR', 'GR'],
      14: ['AT', 'BG', 'CY', 'IT', 'LT', 'ES', 'CN', 'KR'],
    }
    this.update(ageGate, ageGate, () => {})
  }

  fallbackAgeLimit(cc) {
    if (this.defaultAgeLimits['18'].includes(cc)) {
      return 18
    } else if (this.defaultAgeLimits['16'].includes(cc)) {
      return 16
    } else if (this.defaultAgeLimits['15'].includes(cc)) {
      return 15
    } else if (this.defaultAgeLimits['14'].includes(cc)) {
      return 14
    } else {
      return 13
    }
  }

  update(ageGate, sPC, resolve) {
    this.onResultSent = false
    if (ageGate === undefined) {
      this.data = this.data || {}
      this.valid = false
    } else {
      this.data = ageGate // store it so it's available
      this.valid = true
    }
    if (sPC === undefined) {
      this.ageLimit = this.fallbackAgeLimit(false)
    } else {
      this._ctx.debug.log('INFO', JSON.stringify(sPC))
      this.ageLimit =
        sPC.ageLimit !== undefined
          ? Number(sPC.ageLimit)
          : this.fallbackAgeLimit(sPC.countryCode)
      this._ctx.setProp('status', status.READY)
      if (this._ctx.sentryCaptureMessageEnabled)
        Sentry.captureMessage('[Age Gate] onInit')
    }
    this.setDefaultYear(sPC)

    this.now = new Date().getFullYear()
    this.updateView() // initialise sliding component
    this._ctx.debug.log('INFO', 'agegate INIT')

    // if sPC.p.autoResolveValue is provided it should take precedence and we need to
    // resolve compliance immediately setting only passed parameter
    if (sPC && typeof sPC.autoResolveValue !== 'undefined') {
      Sentry.addBreadcrumb({
        type: 'user',
        category: 'resolution',
        message: '[Age Gate] autoResolveValue',
        timestamp: new Date(),
      })
      this.resolvedBy = 'autoResolveValue'
      this.overridePassed(sPC.autoResolveValue)
      this.confirm()
      resolve(navigate.DONT_NAVIGATE)
    } else if (
      // if O7Compliance_BirthYear is provided and user has sP.age_gate object present, resolve compliance immediately
      ageGate !== undefined &&
      this._ctx.cData &&
      keyExists('O7Compliance_BirthYear', this._ctx)
    ) {
      Sentry.addBreadcrumb({
        type: 'user',
        category: 'resolution',
        message: '[Age Gate] O7Compliance_BirthYear',
        timestamp: new Date(),
      })
      this.resolvedBy = 'O7Compliance_BirthYear'
      this.setAgeGate(this._ctx.cData.lS.O7Compliance_BirthYear)
      this.confirm()
      resolve(navigate.DONT_NAVIGATE)
    } else if (
      // if O7Compliance_BirthYear is provided && has passed PIPL consent screen
      this._ctx.cData &&
      this._ctx.cData.sP &&
      this._ctx.cData.sP.consent_pipl &&
      keyExists('O7Compliance_BirthYear', this._ctx)
    ) {
      Sentry.addBreadcrumb({
        type: 'user',
        category: 'resolution',
        message: '[Age Gate] O7Compliance_BirthYear',
        timestamp: new Date(),
      })
      this.resolvedBy = 'O7Compliance_BirthYear'
      this.setAgeGate(this._ctx.cData.lS.O7Compliance_BirthYear)
      this.confirm()
      resolve(navigate.DONT_NAVIGATE)
    } else {
      resolve(navigate.NAVIGATE)
    }
  }

  updateView() {
    if (this.Slider) {
      this.Slider.update()
    } else {
      setTimeout(() => {
        this.updateView()
      }, 100)
    }
  }

  setAgeGate(year) {
    if (year !== -1) {
      this.data.age = this.now - year
      this.data.year = year
      this.data.passed = this.data.age > this.ageLimit
      // this._ctx.debug.log('DEBUG', 'set AGE')
    }
  }

  overridePassed(passed) {
    this.data.year = -1
    this.data.passed = passed
  }

  setDefaultYear(sPC) {
    this.defaultYear = sPC
      ? sPC.defaultYear
        ? Number(sPC.defaultYear)
        : new Date().getFullYear() - (this.ageLimit + 1)
      : new Date().getFullYear() - (this.ageLimit + 1)
  }

  getDefaultYear() {
    return this.defaultYear
  }

  isAgeSet() {
    if (this.data.year !== undefined && this.data.age !== undefined)
      return this.data.year !== -1
    else return false
  }

  confirm() {
    var res = {
      rT: '',
    }
    if (!this.onResultSent) {
      this.onResultSent = true
      if (this._ctx.cData) {
        // validate
        this._ctx.cData = resValidate(this._ctx.cData, 'age_gate')
        // fill data
        this._ctx.cData.sP.age_gate.v = this._ctx.version
        this._ctx.cData.sP.age_gate.t = new Date().getTime()
        this._ctx.cData.sP.age_gate.r = {} // clear result object, before populating it
        this._ctx.cData.sP.age_gate.r.passed = this.data.passed.toString()
        setCLSValue(
          'O7Compliance_AgeLimitPassed',
          this.data.passed.toString() === 'true',
          this._ctx
        )
        this._ctx.cData.sP.age_gate.r.countryCode = this._ctx.country
        this._ctx.cData.sP.age_gate.r.ageLimit = this.ageLimit
        this._ctx.cData.sP.age_gate.r.gts = this._ctx.gts
        setCLSValue(
          'O7Compliance_BirthYear',
          this.data.year !== -1 ? this.data.year : null,
          this._ctx
        )
        this._ctx.cData.gvl = null // remove gvl to save on parsing performance on client
        res.rT = 'success'
        res.p = this._ctx.cData
      } else {
        res.rT = 'break'
        res.failReason = 'CMD was not initialised!'
      }
      this._ctx.debug.log('INFO', 'sendComplianceResult')
      this._ctx.debug.log('DEBUG', JSON.stringify(res))
      if (this._ctx.sentryCaptureMessageEnabled)
        Sentry.captureMessage('[Age Gate] onResult ' + this.resolvedBy)
      call('onResult', JSON.stringify(res), this._ctx)
    } else {
      res.rT = 'break'
      res.failReason = 'AgeGate result was already sent!'
      this._ctx.debug.log('INFO', 'logComplianceResult')
      this._ctx.debug.log('DEBUG', JSON.stringify(res))
      if (this._ctx.sentryCaptureMessageEnabled)
        Sentry.captureMessage('[Age Gate] onResult already sent')
    }
  }
}

export default AgeGate
